export const PERMISSION = {
  SYS: "系统管理",
  NEWS: "资讯管理",
  USER: "用户管理",
  BI: "投注管理",
  FR: "资金管理",
  PERIOD: "期号管理",
  TRADE:"交易管理",
  //PROXY:"代理管理",
  PROXYOPR:"代理操作",
};

export const FUND_MODE = {
  IN: "收入",
  OUT: "支出",
};

export const FUND_TYPE = {
  SYS_IN: "系统添加",
  SYS_OUT: "系统扣除",
  RECHARGE: "充值",
  BET: "投注",
  BET_REPEAT: "投注-追号",
  WIN_BET: "中奖",
  WITHDRAWALS: "提款",
  WITHDRAWALS_FAIL: "提款失败,退回资金",
  TRANS_ORDER_SUCCESS: "转账充值成功",
  PROXY_RECHARGE: "代理充值",
  PROXY_TRANSFER: "代理转账",
  PROXY_REBATE: "代理返点",
};

export const LOTTERY_TYPE = {
  BINGO18: "Bingo18",
  KENO: "Keno",
};

export const RPI_STATUS = {
  PROCESSING: "进行中",
  ABORTED: "失败,放弃",
  DONE: "完成",
};

export const SALE_STATUS = {
  WAIT: "未开售",
  PROCESSING: "开售中",
  STOP: "暂停销售",
  END: "结束销售",
};

export const PLAY_TYPE = {
  B18_M_1: "出现1次",
  B18_M_2: "出现2次",
  B18_M_3: "出现3次",
  B18_TOTAL: "总数",
  B18_GDS: "大中小",
};

export const B18_GDS = {
  G: "大(>11)",
  D: "中(=10,11)",
  S: "小(<9)",
};

export const BET_RESULT = {
  WAIT: "待开奖",
  WIN: "赢",
  LOSE: "输",
  PASS: "跳过",
};

export const PRIZE_STATUS = {
  WAIT: "待派奖",
  DONE: "已派奖",
  NONE: "无需派奖",
};

export const NEWS_TYPE = {
  ANNOUNCEMENT: "公告",
};

export const PAYMENT_METHOD = {
  MOMO: "momo支付",
  DIRE: "泰亚"
};

export const RECHARGE_ORDER_STATUS = {
  PROCESSING: "支付中",
  SUCCESS: "成功",
  FAIL: "失败",
};

export const WITHDRAWSL_ORDER_STATUS = {
  PROCESSING: "处理中",
  SUCCESS: "成功",
  FAIL: "失败",
};

export const WITHDRAWSL_METHOD = {
  BANK: "银行卡",
};


export const TRANS_ORDER_STATUS = {
  PROCESSING: "处理中",
  SUCCESS: "成功",
  FAIL: "失败",
};

export const TRANSFER_METHOD = {
  MANUAL: "人工转账",
  DIRE: "泰亚"
};